<template>
  <CCard color="white" text-color="black" class="text-center" body-wrapper>
    <CRow>
      <CCol md="12">
        <h2 class="font-weight-normal">{{ $t('sendVoucherHistory') }}</h2>
        <p class="text-description">
          {{ $t('sendVoucherHistoryDialog') }}
        </p>
      </CCol>
    </CRow>
    <hr />

    <CRow>
      <CCol md="12">
        <div class="row">
          <div class="col-md-2 col-sm-12 pt-2">
            <p class="font-weight-normal">{{ $t('searchBy') }}</p>
          </div>
          <div class="col-md-2 col-sm-12  mb-2"> <select class="form-control" v-model="attr">
              <option v-for="opt in option" :key="opt.value" :value="opt.value" @click="clearKeyword()">
                {{ opt.label }}
              </option>
            </select>
          </div>
          <div class="col-md-6 col-sm-12 mb-2 " v-if="attr === 'date'">
            <VueDatePicker class=" datepicker-container" color="#29B46B" placeholder="Choose date"
              :locale="getDateLocale()" :visible-years-number="80" v-model="keywordSearch" format="YYYY-MM-DD" />
          </div>
          <div class="col-md-6 mb-2 col-sm-12" v-else-if="attr === 'member' || attr === 'voucherCode' || attr === ''">
            <input v-model="keyword" type="text" class="form-control" :placeholder="$t('keyword' + ' ...')" />
          </div>
          <div class="col-md-2 col-sm-12 mb-2">
            <CButton v-if="loadingButton === true" color="success" block @click="getVoucherTransaction()">
              {{ $t('search') }}
            </CButton>
            <CButton v-else-if="loadingButton === false" color="success" block disabled>
              <CSpinner color="white" size="sm" /> {{ $t('search') }}
            </CButton>
          </div>
        </div>

        <CDataTable :items="items" :fields="fields" hover border clickableRows @row-clicked="toggleDetails">
          <template #isUsed="{ item }">
            <td>
              <CBadge :color="getBadge(item.isUsed)">
                {{ item.isUsed }}
              </CBadge>
            </td>
          </template>
        </CDataTable>

        <CModal :show.sync="detailModal" centered header="false" :footer="footer" size="lg">
          <CRow>
            <CCol md="12">
              <table style="width:100%" aria-describedby="">
                <tr class="text-left">
                  <th scope="col" style="width:70%">
                    <p>
                      <strong>{{ $t('customerName') }} : </strong>
                      {{ itemDetail.member }}
                    </p>
                  </th>
                  <td>
                    <p><strong>{{ $t('date') }} : </strong> {{ itemDetail.date }}</p>
                  </td>
                </tr>
                <tr class="text-left">
                  <td>
                    <p><strong>{{ $t('address') }} : </strong>-</p>
                  </td>
                  <td>
                    <p>
                      <strong>{{ $t('paidBy') }} : </strong>
                      {{ itemDetail.paymentMethod }}
                    </p>
                  </td>
                </tr>
              </table>

              <hr />
              <table border="" style="width:100%" aria-describedby="">
                <th scope="col">{{ $t('index') }}</th>
                <th scope="col">{{ $t('item') }}</th>
                <th scope="col">{{ $t('quantity') }}</th>
                <th scope="col">{{ $t('priceUnit') }}</th>
                <th scope="col">{{ $t('discount') }}</th>
                <th scope="col">{{ $t('grandTotal') }}</th>
                <tr class="text-center" style="vertical-align: top;">
                  <td style="width:10%">
                    <p>1</p>
                  </td>
                  <td class="text-left" style="width:40%">
                    <p>
                      {{ itemDetail.voucherTitle }}
                    </p>
                    <p>
                      <strong>Serial Number :</strong>
                      {{ itemDetail.voucherSerialNumber }}
                      <br />
                      <strong>Voucher Code :</strong>
                      {{ itemDetail.voucherCode }}
                      <br />
                      <strong>{{ $t('expiryDate') }} :</strong>
                      {{ itemDetail.expirationAt }}
                    </p>
                    <p style="font-size:12px" v-if="itemDetail.remark !== ''">
                      <strong>{{ $t('noteCrm') }} </strong>{{ itemDetail.remark }}
                    </p>
                  </td>
                  <td style="width:10%">
                    <p>1</p>
                  </td>
                  <td class="text-right" style="width:10%">
                    <p>{{ itemDetail.voucherValue }}</p>
                  </td>
                  <td class="text-right" style="width:10%">
                    <p>0.00</p>
                  </td>
                  <td class="text-right">
                    <p>{{ itemDetail.paymentAmount }}</p>
                  </td>
                </tr>
              </table>

              <br />

              <table style="width:100%" class="text-left" aria-describedby="">
                <tr style="vertical-align: top;">
                  <th scope="col" style="width:10%">
                    <p v-if="itemDetail.amountText !== ''">
                      <strong>{{ $t('grandTotal') }} </strong>
                    </p>
                  </th>
                  <td style="width:60%">
                    <p>{{ itemDetail.amountText }}</p>
                  </td>
                  <td class="font-weight-normal">
                    <h6><strong>{{ $t('grandTotal') }} : </strong></h6>
                    <h6><strong>{{ $t('discount') }} : </strong></h6>
                    <h6><strong>{{ $t('totalAmount') }} : </strong></h6>
                  </td>
                  <td class="text-right">
                    <h6>
                      {{ itemDetail.paymentAmount }}
                    </h6>
                    <h6>
                      0.00
                    </h6>
                    <h6>
                      {{ itemDetail.paymentAmount }}
                    </h6>
                  </td>
                </tr>
              </table>

              <div class="text-left">
                <p>
                  <strong>{{ $t('paymentReport') }} (Conditions of Payments)</strong>
                </p>

                <p>- {{ itemDetail.paymentMethod }}</p>
                <p><strong>{{ $t('additionalDetail') }}</strong></p>
                <h6>{{ itemDetail.paymentNote }}</h6>
                <img alt="" :src="itemDetail.paymentTransferSlipImgUrl" class="img-fluid" width="30%" />
              </div>
            </CCol>
          </CRow>
          <template #footer-wrapper>
            <br />
          </template>
        </CModal>

        <pagination :meta_data="meta_data" v-on:next="getVoucherTransaction">
        </pagination>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import moment from 'moment'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import THBText from 'thai-baht-text'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: {},
      detail: [],
      member: '',
      memberObjectId: '',
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      attr: '',
      keyword: '',
      detailModal: false,
      footer: '',
      itemDetail: '',
      option: [
        { value: 'date', label: this.$i18n.t('date') },
        { value: 'member', label: this.$i18n.t('memberMenu') },
        { value: 'voucherCode', label: this.$i18n.t('voucherCode') },
      ],
      keywordSearch: null,
      custom: {
        name: 'th',
        weekdays: 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
        weekdaysShort: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
        weekStart: 1,
        months: 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
        monthsShort: 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd D MMMM YYYY HH:mm',
        },
      }
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    fields() {
      return [
        {
          key: 'index',
          label: '#',
          _classes: 'text-center font-weight-normal text-dark',
        },
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _classes: 'text-left font-weight-normal text-dark',
        },
        { key: 'member', label: this.$i18n.t('memberMenu'), _classes: 'text-dark text-left font-weight-normal' },
        {
          key: 'voucherCode',
          label: this.$i18n.t('voucherCode'),
          _classes: ' text-dark text-left font-weight-normal text-dark ',
        },
        { key: 'voucherTitle', label: this.$i18n.t('item'), _classes: ' text-dark text-left font-weight-normal' },
        { key: 'isUsed', label: this.$i18n.t('status'), _classes: 'text-dark text-center font-weight-normal' },
      ]
    },
    items() {
      let dataVoucher = this.detail
      let info = []
      let isUsed = ''
      let start = this.meta_data.current_page * 50 - 50
      let classes = ''
      let datetime = new Date()
      let payment = {}
      let i = 0

      for (let data of dataVoucher) {
        let expiration = moment(data.endAt).isBefore(datetime)
        let member = data.member.name || ''
        let remark = data.remark || ''
        let voucherValue = util.convertCurrency(data.voucher.value) || 0
        let expirationAt = moment(String(data.endAt)).format('LL') || '-'
        let createdAt =
          moment(String(data.created_at)).format('DD/MM/YYYY - HH:mm A') || '-'

        if (data.isUsed === undefined || data.isUsed === false) {
          if (expiration) {
            isUsed = this.$i18n.t('expired')
            classes = 'text-danger'
          } else {
            isUsed = this.$i18n.t('availableCrm')
            classes = 'text-dark'
          }
        } else if (data.isUsed === true) {
          isUsed = this.$i18n.t('used')
          classes = 'text-dark'
        }

        payment = this.getPayment(data.payment)

        info.push({
          index: start + i + 1,
          date: createdAt,
          member: member,
          voucherCode: data.voucherCode,
          voucher: data.voucher,
          voucherValue: voucherValue,
          voucherTitle: data.voucher.title,
          voucherSerialNumber: data.voucher.serialNumber,
          payment: payment,
          paymentMethod: payment.paymentMethod,
          paymentAmount: payment.paymentAmount,
          paymentNote: payment.paymentNote,
          paymentTransferSlipImgUrl: payment.paymentTransferSlipImgUrl,
          amountText: payment.amountText,
          expirationAt: expirationAt,
          remark: remark,
          isUsed: isUsed,
          _classes: classes,
        })

        i++
      }

      return info
    },
  },
  mounted() {
    this.getVoucherTransaction()
  },
  methods: {
    ...util,
    clearKeyword() {
      this.keyword = ''
    },
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: this.custom }
      }
    },
    getPayment(paymentdata) {
      let payment = {}
      let paymentMethod = ''

      if (paymentdata !== undefined) {
        if (paymentdata.paymentMethod === 'cash') {
          paymentMethod = this.$i18n.t('paymentType0')
        } else if (paymentdata.paymentMethod === 'credit') {
          paymentMethod = this.$i18n.t('paymentType1')
        } else if (paymentdata.paymentMethod === 'promptpay') {
          paymentMethod = this.$i18n.t('paymentType4')
        } else {
          paymentMethod = this.$i18n.t('paymentType10') + '( ' + paymentdata.paymentMethod + ' )'
        }

        payment = {
          payment: paymentdata,
          paymentAmount: util.convertCurrency(paymentdata.amount),
          paymentNote: paymentdata.note,
          paymentTransferSlipImgUrl: paymentdata.transferSlipImgUrl,
          amountText: THBText(paymentdata.amount),
          paymentMethod: paymentMethod,
        }
      } else {
        payment = {
          payment: {},
          paymentAmount: this.$i18n.t('noPaymentDetail'),
          paymentNote: '-',
          paymentTransferSlipImgUrl: '',
          amountText: '',
          paymentMethod: this.$i18n.t('noPaymentDetail'),
        }
      }

      return payment
    },
    getBadge(isUsed) {
      switch (isUsed) {
        case this.$i18n.t('used'):
        case 'ใช้งานแล้ว':
          return 'success'
        case this.$i18n.t('availableCrm'):
        case 'ยังไม่ใช้งาน':
          return 'info'
        case this.$i18n.t('expired'):
        case 'หมดอายุแล้ว':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getVoucherTransaction(page = 1) {
      if (this.attr == 'date') {
        this.keyword = this.keywordSearch
      }

      this.loadingButton = false
      const uid = this.uid
      let params = {
        page: page,
        limit: 50,
        attr: this.attr,
        keyword: this.keyword,
      }
      crm
        .get('/api/v1.0/' + uid + '/voucher/gettransaction', { params: params })
        .then((res) => {
          if (res.data.data !== undefined && res.data.data.length != 0) {
            this.detail = res.data.data
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
          } else {
            this.detail = []
          }
          this.loadingButton = true
        })
        .catch((e) => {
          console.error(e)
        })
    },
    toggleDetails(item) {
      this.detailModal = true
      this.itemDetail = item
    },
  },
}
</script>
<style>
.datepicker-container {
  border: 1px solid #d8dbe0;
  color: #768192;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.vd-picker__input input {
  margin-top: 0.4%;
  font-size: 14px !important;
  color: #768192;
}
</style>
