<template>
  <CCard color="white" text-color="black" class="text-center" body-wrapper>
    <CRow>
      <CCol md="3"></CCol>
      <CCol md="6">
        <h2 class="font-weight-normal text-black">{{ $t('member.giftVoucher') }}</h2>
        <p class="text-description">
          {{ $t('createEVoucher') }}
          <br />
          {{ $t('createEVoucher2') }}
        </p>
      </CCol>
      <CCol md="3">
        <CButton variant="outline" color="dark" block to="/voucher/create">
          + {{ $t('createVoucher') }}
        </CButton>
      </CCol>
    </CRow>

    <hr />
    <br />

    <CRow>
  <CCol v-for="voucher in data" :key="voucher.objectId" md="6">
    <router-link :to="'/voucher/' + `${voucher.objectId}`" class="underline">
      <CCard border-color="greyborder" class="box">
        <CCardBody>
          <CRow class="align-items-center">
            <CCol md="5">
              <img width="100%" alt="" class="img-fluid" :src="voucher.imgUrl" @error="noImgUrl" />
            </CCol>
            <CCol md="7">
              <h4>{{ voucher.title }}</h4>
              <div v-if="voucher.deleted_at != null" class="text-danger" style="font-size:12px">
                {{ $t('voucherIsDeleted') }}
              </div>
              <hr class="bd" />
              <div class="h6 text-description text-center">{{ voucher.description }}</div>
              <div class="text-description">{{ $t('createdAt') }}: {{ createdAt(voucher.created_at) }}</div>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol md="6">
              <div class="text-black" style="font-size:14px">
                {{ $t('sent') }}: {{ voucher.sendVouchersTotal }} {{ $t('item') }}
              </div>
            </CCol>
            <CCol md="6">
              <div class="text-black" style="font-size:14px">
                {{ $t('used') }}: {{ voucher.isUsedTotal }} {{ $t('item') }}
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </router-link>
  </CCol>
</CRow>

  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import crm from '@/services/crm'

export default {
  data() {
    return {
      data: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getVoucher()
  },
  methods: {
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    createdAt(datetime) {
      return moment(datetime).format('LL')
    },
    getVoucher() {
      const uid = this.uid

      let params = {
        type: 0,
      }

      crm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/voucher/get',
        params: params,
      })
        .then((response) => {
          this.data = response.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}

hr.bd {
  height: 1px;
  color: #f2f2f2;
  background-color: #f2f2f2;
  border: none;
}

div.autoShowHide {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.autoShowHide:hover {
  white-space: normal;
  overflow: visible;
  width: 100%;
}

.box:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
</style>
