<template>
  <div v-if="shops" class="card tabs shadow-sm">
    <input
      type="radio"
      id="tab1"
      name="tab-control"
      :checked="isActiveDashboard === true"
    />
    <input
      type="radio"
      id="tab2"
      name="tab-control"
      :checked="isActiveVoucher === true"
    />
    <input
      type="radio"
      id="tab3"
      name="tab-control"
      :checked="isActiveSendVouchers === true"
    />
    <input
      type="radio"
      id="tab4"
      name="tab-control"
      :checked="isActivePreVoucher === true"
    />
    <input
      type="radio"
      id="tab5"
      name="tab-control"
      :checked="isActiveTransaction === true"
    />

    <ul class="ulbar font-weight-normal">
      <li :title="$t('dashboardMenu')">
        <label for="tab1" role="button">
          <em class="fas fa-tachometer-alt d-lg-none d-md-none"></em>
          <br /><span> {{$t('dashboardMenu')}}</span>
        </label>
      </li>
      <li :title="$t('member.giftVoucher')">
        <label for="tab2" role="button">
          <em class="fas fa-money-check d-lg-none d-md-none"></em>
          <br /><span> {{ $t('member.giftVoucher') }}</span>
        </label>
      </li>
      <li :title="$t('sendVoucher')">
        <label for="tab3" role="button">
          <em class="fas fa-share-square  d-lg-none d-md-none"></em>
          <br /><span> {{ $t('sendVoucher') }}</span>
        </label>
      </li>
      <li :title="$t('preVoucher')">
        <label for="tab4" role="button">
          <em class="fas fa-money-check-alt d-lg-none d-md-none"></em>
          <br /><span> {{ $t('preVoucher') }}</span>
        </label>
      </li>
      <li :title="$t('sendVoucherHistory')">
        <label for="tab5" role="button">
          <em class="fas fa-history d-lg-none d-md-none"></em>
          <br /><span> {{ $t('sendVoucherHistory') }}</span>
        </label>
      </li>
    </ul>
    <div class="slider"><div class="indicator"></div></div>
    <div class="content">
      <section>
        <dashboard></dashboard>
      </section>
      <section>
        <voucher></voucher>
      </section>
      <section>
        <send-vouchers></send-vouchers>
      </section>
      <section>
        <pre-voucher></pre-voucher>
      </section>
      <section>
        <send-vouchers-transaction></send-vouchers-transaction>
      </section>
    </div>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'
import SendVouchersTransaction from './Transaction'
import SendVouchers from './SendVouchers'
import Voucher from './Index'
import Dashboard from './Dashboard'
import PreVoucher from './PreVoucherList'
import '@/util/tabs.css'

export default {
  components: {
    SendVouchersTransaction,
    SendVouchers,
    Voucher,
    NoShop,
    Dashboard,
    PreVoucher,
  },
  data() {
    return {
      isActiveDashboard: false,
      isActiveVoucher: false,
      isActiveSendVouchers: false,
      isActiveTransaction: false,
      isActivePreVoucher: false,
    }
  },
  computed: {
    ...mapGetters(['shops']),
  },
  mounted() {
    if (this.$route.hash === '' || this.$route.hash === '#dashboard') {
      this.isActiveDashboard = true
    } else if (this.$route.hash === '#voucher') {
      this.isActiveVoucher = true
    } else if (this.$route.hash === '#sendvouchers') {
      this.isActiveSendVouchers = true
    } else if (this.$route.hash === '#pre-voucher') {
      this.isActivePreVoucher = true
    } else if (this.$route.hash === '#transaction') {
      this.isActiveTransaction = true
    }
  },
}
</script>

<style scoped>
.tabs .slider {
  position: relative;
  width: 20%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
</style>
