<template>
  <CRow class="justify-content-center">
    <CCard color="white" text-color="dark" class="col-md-12" body-wrapper>
      <CRow>
        <CCol class="col-md-4">
          <CJumbotron color="white" border-color="greyborder" class="p-3 mb-3">
            <CCallout color="success">
              <h5 class="text-grey">{{ convertNum(allVoucher) }} {{ $t('item') }}</h5>
              <div class="row">
                <strong
                  class="col-md-10 col-sm-9 h4 font-weight-normal text-success"
                >
                  {{ $t('allVoucher') }}
                </strong>
                <div class="col-md-2 col-sm-3 text-right text-success">
                  <em class="fas fa-ticket-alt fa-2x"></em>
                </div>
              </div>
            </CCallout>
          </CJumbotron>

          <CJumbotron color="white" border-color="greyborder" class="p-3 mb-3">
            <CCallout color="primary">
              <h5 class="text-grey">{{ convertNum(eVoucher) }} {{ $t('item') }}</h5>
              <div class="row">
                <strong
                  class="col-md-10 col-sm-9 h4 font-weight-normal text-primary"
                >
                  {{ $t('eVoucher') }}
                </strong>
                <div class="col-md-2 col-sm-3 text-right text-primary">
                  <em class="fas fa-ticket-alt fa-2x"></em>
                </div>
              </div>
            </CCallout>
          </CJumbotron>

          <CJumbotron color="white" border-color="greyborder" class="p-3">
            <CCallout color="warning">
              <h5 class="text-grey">{{ convertNum(preVoucher) }} {{ $t('item') }}</h5>
              <div class="row">
                <strong class="col-md-10 h4 font-weight-normal text-warning">
                  {{ $t('preVoucher') }}
                </strong>
                <div class="col-md-2 text-right text-warning">
                  <em class="fas fa-ticket-alt fa-2x"></em>
                </div>
              </div>
            </CCallout>
          </CJumbotron>
        </CCol>
        <CCol class="col-md-8">
          <CJumbotron color="white" border-color="greyborder" class="p-5">
            <h4 class="font-weight-normal text-black mt-3">{{ $t('allVoucher') }}</h4>
            <hr />
            <CChartBar
              class="mt-4"
              :datasets="voucherChart.datasets"
              :labels="voucherChart.labels"
              :options="barOptions"
            />
          </CJumbotron>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CJumbotron color="white" border-color="greyborder" class="p-4">
            <h5 class="font-weight-normal text-black">{{ $t('eVoucherUsage') }}</h5>
            <hr />
            <CChartPie
              :datasets="usedTrueVoucher.datasets"
              :labels="usedTrueVoucher.labels"
              :options="doughnutOptions"
            ></CChartPie>
          </CJumbotron>
        </CCol>
        <CCol md="6">
          <CJumbotron color="white" border-color="greyborder" class="p-4">
            <h5 class="font-weight-normal text-black">{{ $t('preVoucherUsage') }}</h5>
            <hr />
            <CChartPie
              :datasets="usedFalseVoucher.datasets"
              :labels="usedFalseVoucher.labels"
              :options="doughnutOptions"
            ></CChartPie>
          </CJumbotron>
        </CCol>
      </CRow>
    </CCard>
  </CRow>
</template>

<script>
import { CChartBar, CChartDoughnut, CChartPie } from '@coreui/vue-chartjs'
import crm from '@/services/crm'
import util from '@/util/util'

export default {
  components: {
    CChartDoughnut,
    CChartPie,
    CChartBar,
  },
  data() {
    return {
      data: [],
      allVoucher: 0,
      eVoucher: 0,
      preVoucher: 0,
    }
  },
  computed: {
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    voucherChart() {
      let data = this.data
      let labels = []
      let total = []

      for (let info of data) {
        total.push(info.totalVoucher)
        if (info.type === null) {
          labels.push('E-VOUCHER')
          this.eVoucher = info.totalVoucher
        } else if (info.type === 1) {
          labels.push('PRE-VOUCHER')
          this.preVoucher = info.totalVoucher
        }
        this.allVoucher += info.totalVoucher
      }

      if (total.length === 0) {
        return {
          labels: ['E-VOUCHER', 'PRE-VOUCHER'],
          datasets: [
            {
              backgroundColor: ['#BA5C80', '#9992D1'],
              data: [0, 0],
              borderWidth: 1,
            },
          ],
        }
      } else {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['#BA5C80', '#9992D1'],
              data: total,
              borderWidth: 1,
            },
          ],
        }
      }
    },
    doughnutOptions() {
      return {
        legend: {
          position: 'bottom',
        },
        responsive: true,
      }
    },
    barOptions() {
      return {
        legend: {
          display: false,
        },
        responsive: true,
      }
    },
    usedTrueVoucher() {
      let data = this.data
      let labels = [this.$i18n.t('used'), this.$i18n.t('availableCrm')]
      let total = []

      for (let info of data) {
        if (info.type === null) {
          total = [info.isUsedTrue, info.isUsedFalse]
        }
      }

      if (total.length === 0) {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['#1AC4C1', '#8581DB'],
              data: [0, 0],
              borderWidth: 1,
            },
          ],
        }
      } else {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['#1AC4C1', '#8581DB'],
              data: total,
              borderWidth: 1,
            },
          ],
        }
      }
    },
    usedFalseVoucher() {
      let data = this.data
      let labels = [this.$i18n.t('used'),  this.$i18n.t('availableCrm')]
      let total = []

      for (let info of data) {
        if (info.type === 1) {
          total = [info.isUsedTrue, info.isUsedFalse]
        }
      }

      if (total.length === 0) {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['#1AC4C1', '#8581DB'],
              data: [0, 0],
              borderWidth: 1,
            },
          ],
        }
      } else {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['#1AC4C1', '#8581DB'],
              data: total,
              borderWidth: 1,
            },
          ],
        }
      }
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    convertNum(num) {
      return util.convertNumber(num)
    },
    getDashboard() {
      const uid = this.uid

      crm
        .get('/api/v1.0/' + uid + '/voucher/getdashboard')
        .then((res) => {
          this.data = res.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
