<template>
  <CCard color="white" text-color="black" class="text-center" body-wrapper>
    <CRow>
      <CCol md="12">
        <CRow>
          <CCol md="3"></CCol>
          <CCol md="6">
            <h2 class="font-weight-normal">{{ $t('preVoucher') }}</h2>
            <p class="text-description">
              {{ $t('preVoucherDialog') }}
            </p>
          </CCol>
          <CCol md="3">
            <CButton
              variant="outline"
              color="dark"
              block
              to="/voucher/prevoucher/create"
            >
              + {{ $t('createPreVoucher') }}
            </CButton>
          </CCol>
        </CRow>

        <hr />
        <br />

        <CRow>
          
  <CCol
    v-for="voucher in data"
    :key="voucher.objectId"
    md="12"
    lg="6"
    class="text-center mb-3"
    
  >
    <router-link
      :to="'/voucher/' + `${voucher.objectId}` + '/prevoucher'"
      class="underline"
    >
      <CCard  class="box">
        <CRow>
          <CCol md="12" >
            <div class="row" >
              <div class="col-md-6 col-sm-12" >
                <div class="p-3">
                  <h4 class="text-black" style="overflow: hidden; white-space: nowrap;text-overflow: ellipsis;">
                    {{ voucher.title }}
                  </h4>
                  <strong v-if="voucher.deleted_at != null" class="text-danger">
                    {{ $t('voucherIsCanceled') }} 
                  </strong>
                  <hr />
                  <p class="text-description" style="overflow-x:hidden;height: 40px;">
                    {{ voucher.description }}
                  </p>
                  <p class="text-black font-weight-normal">
                    SERIAL NO. {{ voucher.serialNumber }}
                  </p>
                  <h6 class="text-grey">
                    {{ $t('total') }} : {{ voucher.sendVouchersTotal }}  {{ $t('item') }}
                  </h6>
                  <h6 class="text-grey">
                    {{ $t('used') }} : {{ voucher.isUsedTotal }}  {{ $t('item') }}
                  </h6>
                  <br />
                  <p class="text-description font-weight-normal" style="font-size:12px">
                    {{ $t('createdAt') }} : {{ createdAt(voucher.created_at) }}
                  </p>
                </div>
              </div>
              <div class="col" >
                    <img :src="voucher.imgUrl" alt="Voucher Image" class="img-fluid voucherimg">
                  </div>
            </div>
          </CCol>
        </CRow>
      </CCard>
    </router-link>
  </CCol>
</CRow>

      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import crm from '@/services/crm'

export default {
  data() {
    return {
      data: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  created() {
    this.getVoucher()
  },
  methods: {
    imgUrl(img) {
      if (img === '' || img === undefined || img === null) {
        return 'https://cdn.pixabay.com/photo/2014/11/27/22/44/gift-548291_1280.jpg'
      } else {
        return img
      }
    },
    createdAt(datetime) {
      return moment(datetime).format('LL')
    },
    getVoucher() {
      const uid = this.uid

      let params = {
        type: 1,
      }

      crm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/voucher/get',
        params: params,
      })
        .then((response) => {
          this.data = response.data.data.documents
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}


@media (min-width: 767px) {
  .voucherimg{
  height: 100%;
  width:100%;
  object-fit:cover;
  -webkit-mask-image: radial-gradient(circle at 6px, transparent 6px, red 6.5px);
-webkit-mask-position: -6px;
-webkit-mask-size: 100% 17px;

}
.box {
  height: fit-content;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(240, 240, 240); /* Add a transparent border to all sides */
  border-right: none; /* Remove the border on the right side */
}
    }

@media (max-width: 770px) {
  .voucherimg {
    max-height: 310px;
  height: 100%;
  width:100%;
  object-fit:cover;
        }
        .box {
  height: fit-content;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(240, 240, 240); /* Add a transparent border to all sides */
  
}
    }
</style>
